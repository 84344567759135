import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'

import UAParser from 'ua-parser-js'
import clsx from 'clsx'

import getUtils from 'wapplr-react/dist/common/Wapp/getUtils'
import { WappContext } from 'wapplr-react/dist/common/Wapp'

import Button from 'vlm-ui/dist/common/src/components/Button'
import Image from 'vlm-ui/dist/common/src/components/Image'

import FacebookIcon from 'vlm-ui/dist/common/src/svg/FacebookIcon'
import InstagramIcon from 'vlm-ui/dist/common/src/svg/InstagramIcon'
import barionImage from '../../images/barion-card-strip-intl__small.png'

import DashboardIcon from 'vlm-ui/dist/common/src/svg/DashboardIcon'
import LogoutIcon from 'vlm-ui/dist/common/src/svg/LogoutIcon'
import AddCircleIcon from 'vlm-ui/dist/common/src/svg/AddCircleIcon'
import MoneyIcon from 'vlm-ui/dist/common/src/svg/MoneyIcon'

import { copyObject } from 'wapplr/dist/common/utils'

import '../Template/component.css'
import defaultStyle from './component.css'

import { runPostTypesConfigSync } from '../../postTypes'

import messages from '../../config/constants/messages'
import labels from '../../config/constants/labels'
import titles from '../../config/constants/titles'
import routes from '../../config/constants/routes'
import menus from '../../config/constants/menus'

import Template from '../Template'
import Account from '../Account'
import NotFound from '../NotFound'
import Cookies from '../Cookies'
import Dialog from '../Dialog'

import AppContext from './context'

import { clear as localStorageClear, storage as localStorage } from './localStorage'
import { clear as memoStorageClear, storage as memoStorage } from './memoStorage'

import { getProfessionMenu } from './getProfessionMenu'

import DesktopMenuStaticContent from './DesktopMenuStaticContent'

function getUserMenu(props) {

    const { appContext, context, classNames } = props

    const utils = getUtils(context)

    const employeeMenu = getProfessionMenu({ parentRoute: routes.employeesRoute, utils, appContext, name: 'user' })

    const adminMenu = runPostTypesConfigSync({
        action: 'adminMenu',
        p: { ...props, appContext, utils }
    }).filter((m) => m)
    const editorMenu = runPostTypesConfigSync({
        action: 'editorMenu',
        p: { ...props, appContext, utils }
    }).filter((m) => m)

    return [
        {
            label: appContext.menus.dashboardMenu,
            href: (p) => appContext.routes.userRoute + '/' + p.user?._id,
            role: (p) => p?.user?._id,
            startIcon: <DashboardIcon />
        },
        {
            label: 'Új álláshirdetés',
            href: appContext.routes.jobRoute + '/new',
            role: (p) => {
                return p.user?._id
            },
            startIcon: <AddCircleIcon />
        },
        {
            label: 'Kredit vásárlás',
            href: appContext.routes.orderRoute + '/new',
            role: (p) => {
                return p.user?._id
            },
            startIcon: <MoneyIcon />
        },
        /*{
            label: appContext.menus.accountSettingsMenu,
            href: (p) => appContext.routes.userRoute + '/' + p.user?._id + '/profilesettings',
            role: (p) => p?.user?._id,
            startIcon: <SettingsIcon />
        },*/
        {
            label: appContext.menus.logoutMenu,
            //href: appContext.routes.accountRoute + '/logout',
            onClick: async (e) => {
                if (e) {
                    e.preventDefault()
                }
                await utils.logout({
                    requestName: 'userLogout',
                    redirect: { pathname: appContext.routes.accountRoute + '/login', search: '', hash: '' }
                })
            },
            role: (p) => p?.user?._id && !p.isMobileMenu,
            startIcon: <LogoutIcon />
        },
        {
            divider: true,
            order: 199,
            role: function(p) {
                return (p.user && p.user._status_isFeatured) || (p.user && p.user._isEditor)
            }
        },
        {
            label: 'Admin',
            role: function(p) {
                return p.user && p.user._status_isFeatured
            },
            order: 200,
            MenuBaseProps: {
                PaperProps: {
                    className: classNames.adminPaper
                }
            },
            items: [
                {
                    label: appContext.labels.employeesMenu,
                    items: [
                        {
                            label: appContext.labels.employeesMenu,
                            href: routes.employeesRoute
                        },
                        {
                            divider: true
                        },
                        ...employeeMenu
                    ]
                },
                ...adminMenu.reduce((a, menu) => {
                    if (Array.isArray(menu)) {
                        a.push(...menu)
                    } else {
                        a.push(menu)
                    }
                    return a
                }, [])
            ],
            menuType: 'list'
        },
        {
            label: 'Admin',
            role: function(p) {
                return p.user && p.user._isEditor
            },
            order: 200,
            MenuBaseProps: {
                PaperProps: {
                    className: classNames.adminPaper
                }
            },
            items: [
                ...editorMenu.reduce((a, menu) => {
                    if (Array.isArray(menu)) {
                        a.push(...menu)
                    } else {
                        a.push(menu)
                    }
                    return a
                }, [])
            ],
            menuType: 'list'
        }
    ]
}

function OfflineLayer(props) {

    const [show, setShow] = useState(props.show || false)

    useEffect(() => {
        if (props.effect) {
            props.effect({
                actions: {
                    setShow
                }
            })
        }
        return () => {
            if (props.effect) {
                props.effect({
                    actions: {
                        setShow: async () => null
                    }
                })
            }
        }
    })

    return (
        <div className={clsx(
            props.classNames.offlineLayer,
            { [props.classNames.offlineLayerShow]: show }
        )} />
    )
}

function NewReleasePopup(props) {

    const context = useContext(WappContext)
    const appContext = useContext(AppContext)

    const { wapp } = context
    const { effect } = props

    const dialog = useRef()

    useEffect(() => {
        if (effect) {
            effect({
                actions: {
                    open: () => {
                        dialog.actions?.open({
                            dialogTitle: appContext.titles.newReleaseWarningTitle,
                            dialogContent: appContext.messages.newReleaseWarningContent,
                            cancelText: appContext.labels.newReleaseCancelText,
                            submitText: appContext.labels.newReleaseSubmitText,
                            onSubmit: async () => {
                                await wapp.pwaUnregister()
                                window.location.reload()
                            }
                        })
                    }
                }
            })
        }
    })

    return (
        <Dialog
            effect={({ actions }) => {
                dialog.actions = actions
            }}
            ContentComponentProps={{
                Component: 'div'
            }}
        />
    )
}

export default function App(p = {}) {

    const context = useContext(WappContext)
    const { wapp, req, res } = context
    const utils = getUtils(context)

    const props = {
        ...p,
        ...res.wappResponse.content?.ComponentProps ? res.wappResponse.content.ComponentProps : {}
    }

    const {
        subscribe,
        parentRoute = '',
        userPostTypeName = 'user',
        fullPage,
        classNames = defaultStyle,
        TemplateProps = {},
        PostTypeComponentProps = {}
    } = props

    //wapp.styles.use(classNames)

    const [url, setUrl] = useState(utils.getRequestUrl())
    const [user, setUser] = useState(utils.getRequestUser())

    const template = useRef()
    const waitForRender = useRef(true)
    const buttonLastHrefPushToHistory = useRef({})
    const networkStateOffline = useRef(false)
    const offlineLayer = useRef()
    const newRelease = useRef()

    useEffect(() => {
        if (wapp.target === 'web' && wapp.client) {
            wapp.client.onlineHandler = () => {
                template.current?.actions?.setSnackMessage(appContext.messages.onlineAgain, 3000)
                template.current?.actions?.setLogoBlackAndWhite(false)
                networkStateOffline.current = false
                offlineLayer.actions.setShow(false)
                document.body.style.position = null
                document.body.style.overflow = null
                document.body.style.width = null
                document.body.style.height = null
                if (!shouldLeaveWarning.current) {
                    setTimeout(async () => {
                        await wapp.pwaUnregister()
                        window.location.reload()
                    })
                }
            }
            wapp.client.offlineHandler = () => {
                template.current?.actions?.setSnackMessage(appContext.messages.offlineWarning, 6000, 'error')
                template.current?.actions?.setLogoBlackAndWhite(true)
                networkStateOffline.current = true
                offlineLayer.actions.setShow(true)
                document.body.style.position = 'fixed'
                document.body.style.overflow = 'hidden'
                document.body.style.width = '100vw'
                document.body.style.height = '100vh'
            }
            wapp.client.serviceWorkerUpdated = () => {
                if (!networkStateOffline.current) {
                    newRelease.actions?.open()
                }
            }
            wapp.client.handleNotFoundResponse = () => {
                if (!networkStateOffline.current) {
                    newRelease.actions?.open()
                }
            }
        }
    }, [])

    const storageName = wapp.globals.NAME
    const storage = function(data, memo) {
        if (memo) {
            return memoStorage((data) ? data : {}, storageName)
        }
        return localStorage(data, storageName)
    }

    const storageClear = function(memo) {
        if (memo) {
            return memoStorageClear(storageName)
        }
        return localStorageClear(storageName)
    }

    function saveScrollTopAfterFinishedRender() {
        const urlKey = wapp.client.history.getState().key || 'initial'
        let scrollTop = storage(undefined, true)['scrollTop_' + urlKey] || 0
        storage({ ['shouldScrollTopAfterRender']: scrollTop }, true)
    }

    async function setScrollTop() {

        let scrollTop = storage(undefined, true)['shouldScrollTopAfterRender']
        let smooth = false

        if (scrollTop >= 0) {
            storage({ ['shouldScrollTopAfterRender']: -1 }, true)
            try {
                if (scrollTop === 0 && window.location.hash) {
                    const e = document.getElementById(window.location.hash.slice(1))
                    if (e) {

                        const maxScrollY = template.current?.actions?.getMaxScrollY() || 0
                        scrollTop = e.getBoundingClientRect().top + window.scrollY - 100 - 16

                        if (maxScrollY < scrollTop && maxScrollY) {
                            scrollTop = maxScrollY
                        }

                        smooth = true
                    }
                }
            } catch (e) {
            }

            if (template.current?.actions?.setScrollTop) {
                await template.current.actions.setScrollTop(scrollTop, smooth)
            }

        }
    }

    function createStates() {
        if (!wapp.states.stateManager.actions.app) {
            wapp.states.stateManager.actions.app = function({ type, name, value }) {
                return {
                    type: type || 'SET_APP',
                    payload: {
                        name,
                        value
                    }
                }
            }
            wapp.states.stateManager.reducers.app = function(state = {}, action) {
                switch (action.type) {
                    case 'SET_APP':
                        return {
                            ...state,
                            [action.payload.name]: (action.payload.value && typeof action.payload.value == 'object') ? copyObject(action.payload.value) : action.payload.value
                        }
                    default:
                        return state
                }
            }

            if (wapp.target === 'web') {
                wapp.client.history.globalHistory.scrollRestoration = 'manual'
            }

        }
    }

    function storeUrl(action, url, { key = 'initial' }) {

        const history = res.wappResponse.store.getState('app.history') || []

        const lastItem = history[history.length - 1]

        if (lastItem && lastItem.key !== key || !lastItem) {

            const current = {
                action: action || 'PUSH',
                url: url,
                key,
                referrer: lastItem?.url || ''
            }

            history.push(current)

            res.wappResponse.store.dispatch(wapp.states.runAction('app', { name: 'history', value: history }))
            res.wappResponse.store.dispatch(wapp.states.runAction('app', { name: 'current', value: current }))

        }

    }

    function subscribeAppStore() {

        createStates()

        const unsubscribeFromState = res.wappResponse.store.subscribe(function({ type, payload }) {
            if (wapp.target === 'web' && wapp.globals.DEV) {
                console.log('[APP] Change state:', type, payload)
            }
        })

        const unsubscribeHistoryListener = (wapp.target === 'web') ?
            wapp.client.history.addListener(function({ action, location, state }) {
                storeUrl(action, location.pathname + location.search + location.hash, state)
                if (action === 'POP') {
                    delete buttonLastHrefPushToHistory.current.time
                    delete buttonLastHrefPushToHistory.current.href
                }
            }) : null

        return () => {
            unsubscribeFromState()
            unsubscribeHistoryListener()
        }

    }

    /*todo: here, all user interactions, such as scroll and click, will have to be saved in the app state*/

    if (wapp.target === 'node') {
        createStates()
        storeUrl('PUSH', url, { key: 'initial' })
    }

    useEffect(() => {
        const unsubscribe = subscribeAppStore()
        const key = wapp.client.history.getState().key || 'initial'
        storeUrl('PUSH', url, { key })
        return unsubscribe
    }, [])

    if (wapp.target === 'web') {
        useLayoutEffect(() => {
            if (waitForRender.current) {
                waitForRender.current = false
                setScrollTop()
            }
        }, [url])
    }

    async function onLocationChange(newUrl) {
        if (template.current?.actions?.setLogoAnimation) {
            // noinspection ES6MissingAwait
            template.current.actions.setLogoAnimation(1)
        }
        saveScrollTopAfterFinishedRender()
        if (url !== newUrl) {
            waitForRender.current = true
            setUrl(newUrl)
        } else {
            await setScrollTop()
            if (template.current?.actions?.setLogoAnimation) {
                // noinspection ES6MissingAwait
                template.current.actions.setLogoAnimation(0)
            }
        }
    }

    async function onUserChange(newUser) {

        hb.current.run = false;
        hb.current._id = newUser?._id || null;

        await setUser((newUser?._id) ? newUser : null)

        const shouldClearCache = (newUser?._id !== user?._id)

        if (shouldClearCache) {

            if (wapp.globals.DEV) {
                console.log('[APP] Clear cache')
            }

            if (!newUser?._id) {

                res.wappResponse.store.dispatch(wapp.states.stateManager.actions.res({
                    type: 'SET_RES',
                    name: 'cache',
                    value: {}
                }))

                res.wappResponse.store.dispatch(wapp.states.stateManager.actions.res({
                    type: 'SET_RES',
                    name: 'responses',
                    value: {}
                }))

                storageClear(true)
                storageClear()

                const urlKey = wapp.client.history.getState().key || 'initial'
                storage({ ['scrollTop_' + urlKey]: 0 }, true)
                storage({ ['shouldScrollTopAfterRender']: 0 }, true)

                wapp.pwaClearCaches()

            } else {

                res.wappResponse.store.dispatch(wapp.states.stateManager.actions.res({
                    type: 'SET_RES',
                    name: 'cache',
                    value: {}
                }))

                if (newUser?._status_isFeatured) {
                    res.wappResponse.store.dispatch(wapp.states.stateManager.actions.res({
                        type: 'INS_RES',
                        name: 'responses',
                        value: { 'products': null }
                    }))
                }

            }

        }

        hb.current.run = true;

    }

    /*useEffect(()=>{
        function onError(message, url, lineno) {
            if (message !== "ResizeObserver loop completed with undelivered notifications.") {
                alert("Error:\n\t" + message + "\nLine:\n\t" + lineno + "\nFile:\n\t" + url)
            }
        }
        window.onerror = onError
        return ()=>{
            window.onerror = null
        }
    })*/

    useEffect(function() {
        const unsub1 = subscribe.locationChange(onLocationChange)
        const unsub2 = subscribe.userChange(onUserChange)
        return function useUnsubscribe() {
            unsub1()
            unsub2()
        }
    }, [subscribe, url, user])

    useEffect(() => {
        if (template.current?.actions?.setLogoAnimation) {
            const history = res.wappResponse.store.getState('app.history') || []
            const lastItem = history[history.length - 1]
            if (lastItem.key !== 'initial' && history.length > 1) {
                template.current.actions.setLogoAnimation(0)
            }
        }
    })

    useEffect(() => {
        if (wapp.target === 'web') {
            const cssAssets = res.wappResponse.store.getState('res.assets.css')
            const themeCss = cssAssets.find((css) => css.match('theme'))

            if (themeCss) {

                const criticalCss = cssAssets.find((css) => css.match('critical'))

                function getReference() {
                    const globals = wapp.globals
                    const { WAPP } = globals
                    const criticalStyle = document.getElementById('css_' + WAPP)
                    const criticalLink = (criticalCss) ? document.getElementById(criticalCss) : null
                    return criticalLink || criticalStyle
                }

                async function success() {
                    console.log('[APP] Full theme loaded...')
                    await new Promise((resolve) => setTimeout(resolve, 1500))
                    const reference = getReference()
                    if (reference) {
                        const head = document.getElementsByTagName('head')[0]
                        head.removeChild(reference)
                    }
                }

                async function createLink() {

                    const head = document.getElementsByTagName('head')[0]
                    const link = document.createElement('link')
                    link.rel = 'stylesheet'
                    link.type = 'text/css'
                    link.href = themeCss
                    link.id = themeCss
                    link.onload = async () => {
                        success()
                    }

                    const reference = getReference()

                    head.insertBefore(link, reference ? reference.nextSibling : null)

                }

                console.log('[APP] Hydrated')

                if (!document.getElementById(themeCss)) {
                    function add() {
                        if (!document.getElementById(themeCss)) {
                            if (window.requestIdleCallback) {
                                window.requestIdleCallback(createLink)
                            } else {
                                createLink()
                            }
                        }
                    }

                    function on(e) {
                        if (e.isTrusted) {
                            add()
                            removeListeners()
                        }
                    }

                    function removeListeners() {
                        window.removeEventListener('touchstart', on)
                        window.removeEventListener('mousemove', on)
                    }

                    window.addEventListener('touchstart', on)
                    window.addEventListener('mousemove', on)

                    return () => {
                        removeListeners()
                    }

                }

            }
        }
    }, [])

    const hb = useRef({run: true, _id: user?._id || null})
    async function checkUser(instance) {
        await new Promise((resolve) => setTimeout(resolve, 5000))
        hb.current.wasInteraction = false;
        await new Promise((resolve)=>{
            if (hb.current.interval) {
                clearInterval(hb.current.interval)
            }
            if (hb.current.timeout) {
                clearTimeout(hb.current.timeout)
            }
            hb.current.interval = setInterval(()=>{
                if (hb.current.wasInteraction) {
                    if (hb.current.interval) {
                        clearInterval(hb.current.interval)
                    }
                    if (hb.current.timeout) {
                        clearTimeout(hb.current.timeout)
                    }
                    resolve();
                }
            }, 100)
            hb.current.timeout = setTimeout(()=>{
                if (hb.current.interval) {
                    clearInterval(hb.current.interval)
                }
                if (hb.current.timeout) {
                    clearTimeout(hb.current.timeout)
                }
                resolve();
            }, 1000 * 60 * 10)
        })
        let response = await wapp.requests.send({
            requestName: 'userHb',
            args: { _id: hb.current._id },
            req,
            res
        })
        if (response && response['userHb']) {
            response = response['userHb']
        }
        if (!response?.sa) {
            console.log('[APP] Need update user')
            if (instance.update) {
                await instance.update()
            }
        }
        if (instance.run && hb.current.run) {
            await checkUser(instance)
        }
    }
    function addCheckUserInteractionListener() {

        if (hb.current.removeListeners) {
            hb.current.removeListeners();
        }

        function on(e) {
            if (e.isTrusted && hb.current.run) {
                hb.current.wasInteraction = true;
            }
        }

        hb.current.removeListeners = function() {
            window.removeEventListener('touchstart', on)
            window.removeEventListener('mousemove', on)
            hb.current.removeListeners = null;
        }

        window.addEventListener('touchstart', on)
        window.addEventListener('mousemove', on)

        return hb.current.removeListeners;

    }
    function syncRunCheckUser() {
        const instance = {
            run: true,
            update: async ()=>{

                let response = await wapp.requests.send({
                    requestName: 'userUpdateAuth',
                    args: {},
                    req,
                    res
                })

                if (response && response['userUpdateAuth']) {
                    response = response['userUpdateAuth']
                }

                res.wappResponse.store.dispatch(wapp.states.runAction("req", {
                    name: "user",
                    value: response?._id ? response : null
                }));

                wapp.client.history.push({
                    search: '',
                    hash: '',
                    ...wapp.client.history.parsePath(res.wappResponse.store.getState("req.url"))
                })
            }
        }
        addCheckUserInteractionListener();
        checkUser(instance)
        return () => {
            instance.run = false
            instance.update = null
            if (hb.current.removeListeners) {
                hb.current.removeListeners()
            }
        }
    }

    useEffect(() => {
        return syncRunCheckUser()
    }, [user?._id])

    const userStatusManager = wapp.getTargetObject().postTypes.findPostType({ name: userPostTypeName }).statusManager

    const shouldLeaveWarning = useRef(false)
    const dialog = useRef()

    const { device } = new UAParser(req.wappRequest.userAgent).getResult()

    const appContext = {
        messages,
        labels,
        titles,
        routes,
        menus,
        userStatusManager,
        userPostTypeName,
        storage,
        template,
        account: {
            termsSlug: 'aszf',
            privacySlug: 'adatkezeles'
        },
        job: {
            activateInfoSlug: 'hirdetes-feladas-info'
        },
        setShouldLeaveWarning: (value) => {
            shouldLeaveWarning.current = value
        },
        getShouldLeaveWarning: () => shouldLeaveWarning.current,
        device
    }

    const PostTypesComponent = runPostTypesConfigSync({
        action: 'getComponent',
        p: { context, appContext }
    }).filter((C) => !!(C))[0]

    const route = res.wappResponse.route
    const requestPath = route.requestPath

    const professionMenu = getProfessionMenu({ parentRoute: routes.jobsRoute, utils, appContext, name: 'job' })

    const history = res.wappResponse.store.getState('app.history') || []
    const lastItem = history[history.length - 1]
    const probablyWaitingForLogout = (lastItem?.url?.startsWith(appContext.routes.accountRoute + '/login'))

    return (
        <AppContext.Provider value={appContext}>
            <Template
                {...TemplateProps}
                menu={[
                    {
                        label: appContext.menus.jobsMenu,
                        items: [
                            {
                                label: appContext.menus.allJobsMenu,
                                href: appContext.routes.jobsRoute + '/',
                                color: 'paper1'
                            },
                            {
                                divider: true
                            },
                            ...professionMenu
                        ],
                        desktopMegaMenuStaticContent:
                            user?._id ?
                                null :
                                <DesktopMenuStaticContent classNames={classNames} />
                    },
                    {
                        label: 'Munkáltatóknak',
                        disableMegaMenu: true,
                        role: (p) => {
                            const {
                                isMobileMenu,
                                user
                            } = p
                            return !user?._id || (!isMobileMenu && user?._id)
                        },
                        items: [
                            {
                                label: 'Új álláshirdetés',
                                href: (p) => {
                                    return p?.user?._id ? appContext.routes.jobRoute + '/new' : appContext.routes.accountRoute + '/login' + '?redirect=' + encodeURIComponent(appContext.routes.jobRoute + '/new')
                                }
                            },
                            {
                                label: 'Kredit vásárlás',
                                href: (p)=>{
                                    return p?.user?._id ? appContext.routes.orderRoute + '/new' : appContext.routes.accountRoute + '/login' + '?redirect=' + encodeURIComponent(appContext.routes.orderRoute + '/new')
                                },
                            },
                            {
                                label: 'Miért jó nálunk hirdetni?',
                                href: '/munkaltatoknak'
                            },
                            {
                                label: 'Kapcsolat',
                                href: '/kapcsolat'
                            },
                            {
                                label: 'Árak',
                                href: '/arak'
                            },
                            {
                                label: appContext.menus.signupMenu,
                                href: appContext.routes.accountRoute + '/signup',
                                Component: Button,
                                fullWidth: true,
                                color: 'primary',
                                style: { marginTop: '10px' },
                                role: (p = {}) => {
                                    const {
                                        isMobileMenu,
                                        user
                                    } = p
                                    return !isMobileMenu && !user?._id
                                }
                            },
                            {
                                label: appContext.menus.loginMenu,
                                href: appContext.routes.accountRoute + '/login',
                                Component: Button,
                                fullWidth: true,
                                color: 'secondary',
                                style: { marginTop: '10px' },
                                role: (p = {}) => {
                                    const {
                                        isMobileMenu,
                                        user
                                    } = p
                                    return !isMobileMenu && !user?._id
                                }
                            }
                        ]
                    },
                    {
                        label: 'Munkavállalóknak',
                        disableMegaMenu: true,
                        role: (p) => {
                            const {
                                isMobileMenu,
                                user
                            } = p
                            return !user?._id || (!isMobileMenu && user?._id)
                        },
                        items: [
                            {
                                label: 'Munkavállalói profil',
                                href: (p) => {
                                    return p?.user?.profileId ? appContext.routes.profileRoute + '/' + p?.user?.profileId :
                                        appContext.routes.profileRoute + '/new'
                                },
                                role: (p) => {
                                    return p?.user?._id
                                }
                            },
                            {
                                label: 'A vendéglátós munkákról',
                                href: '/munkavallaloknak'
                            },
                            {
                                label: 'Összes álláshirdetés',
                                href: appContext.routes.jobsRoute
                            },
                            {
                                label: appContext.menus.signupMenu,
                                href: appContext.routes.accountRoute + '/signup',
                                Component: Button,
                                fullWidth: true,
                                color: 'primary',
                                style: { marginTop: '10px' },
                                role: (p = {}) => {
                                    const {
                                        isMobileMenu,
                                        user
                                    } = p
                                    return !isMobileMenu && !user?._id
                                }
                            },
                            {
                                label: appContext.menus.loginMenu,
                                href: appContext.routes.accountRoute + '/login',
                                Component: Button,
                                fullWidth: true,
                                color: 'secondary',
                                style: { marginTop: '10px' },
                                role: (p = {}) => {
                                    const {
                                        isMobileMenu,
                                        user
                                    } = p
                                    return !isMobileMenu && !user?._id
                                }
                            }
                        ]
                    }
                ]}
                userMenu={getUserMenu({ appContext, context, classNames })}
                featuredButtons={[
                    {
                        label: appContext.menus.loginMenu,
                        href: appContext.routes.accountRoute + '/login',
                        role: (p) => !p.user?._id
                    },
                    {
                        label: appContext.menus.signupMenu,
                        href: appContext.routes.accountRoute + '/signup',
                        color: 'tertiary',
                        role: (p) => !p.user?._id
                    },
                    {
                        label: appContext.menus.logoutMenu,
                        //href: appContext.routes.accountRoute + '/logout',
                        onClick: async (e) => {
                            if (e) {
                                e.preventDefault()
                            }
                            await utils.logout({
                                requestName: 'userLogout',
                                redirect: { pathname: appContext.routes.accountRoute + '/login', search: '', hash: '' }
                            })
                        },
                        color: 'tertiary',
                        role: (p = {}) => {
                            const {
                                isMobileMenu,
                                user
                            } = p
                            return isMobileMenu && user?._id
                        }
                    }
                ]}
                handlers={{
                    onScroll: (e) => {
                        if (!waitForRender.current) {
                            if (url === res.wappResponse.store.getState('app.current.url')) {
                                const urlKey = wapp.client.history.getState().key || 'initial'
                                const scrollTop = e.target === document ? window.scrollY : e.target.scrollTop
                                storage({ ['scrollTop_' + urlKey]: scrollTop }, true)
                            }
                        }
                    }
                }}
                effect={({ actions }) => {
                    template.current = {
                        actions
                    }
                }}
                ButtonContextValue={{
                    href: ({ href }) => {

                        if (!href) {
                            return href
                        }

                        let internal = false
                        try {
                            const url = new URL(href, window.location.origin)
                            internal = (url && url.origin === window.location.origin && url.href.startsWith(window.location.origin))
                        } catch (e) {

                        }

                        if (internal) {
                            return href ? parentRoute + href : href
                        }

                        return href
                    },
                    onClick: async function(e, { href, target, disabled }) {

                        if (disabled) {
                            e.preventDefault()
                            e.stopPropagation()
                            return
                        }

                        if (!href || target === '_blank') {

                        } else {

                            try {
                                const hrefUrl = new URL(href, window.location.origin)
                                if (hrefUrl && hrefUrl.origin === window.location.origin && hrefUrl.href.startsWith(window.location.origin)) {

                                    e.preventDefault()
                                    e.stopPropagation()

                                    if (networkStateOffline.current) {
                                        appContext.template.current?.actions?.setSnackMessage(appContext.messages.offlineWarning, 6000, 'error')
                                        return
                                    }

                                    if (shouldLeaveWarning.current) {

                                        dialog.actions.open({
                                            dialogTitle: appContext.titles.leaveWarningTitle,
                                            dialogContent: appContext.messages.leaveWarningMessage,
                                            cancelText: appContext.labels.leaveWarningCancel,
                                            submitText: appContext.labels.leaveWarningSubmit,
                                            onSubmit: async function() {

                                                dialog.actions.close()
                                                shouldLeaveWarning.current = false

                                                if (template.current?.actions?.setLogoAnimation) {
                                                    // noinspection ES6MissingAwait
                                                    template.current.actions.setLogoAnimation(1)
                                                }

                                                wapp.client.history.push({
                                                    search: '',
                                                    hash: '',
                                                    ...wapp.client.history.parsePath(hrefUrl.href.split(window.location.origin)[1])
                                                })
                                            }
                                        })

                                    } else {

                                        let isSamePage = false
                                        try {
                                            const absoluteUrl = new URL(url, window.location.origin)
                                            if (absoluteUrl.href === hrefUrl.href) {
                                                isSamePage = true
                                            }
                                        } catch (e) {

                                        }

                                        const wait = 1000 * (isSamePage ? 5 : 10)
                                        const now = Date.now()

                                        if (buttonLastHrefPushToHistory.current.href === hrefUrl.href && buttonLastHrefPushToHistory.current.time + wait > now) {
                                            console.log('[APP] Prevent click again... wait ' + (buttonLastHrefPushToHistory.current.time + wait - now) + ' ms')
                                            template.current.actions.setLogoAnimation(0)
                                            appContext.template.current?.actions?.setSnackMessage((isSamePage) ? appContext.messages.preventClickDone : appContext.messages.preventClickAgain)
                                            return
                                        }

                                        buttonLastHrefPushToHistory.current.time = Date.now()
                                        buttonLastHrefPushToHistory.current.href = hrefUrl.href

                                        if (template.current?.actions?.setLogoAnimation) {
                                            // noinspection ES6MissingAwait
                                            template.current.actions.setLogoAnimation(1)
                                        }

                                        wapp.client.history.push({
                                            search: '',
                                            hash: '',
                                            ...wapp.client.history.parsePath(hrefUrl.href.split(window.location.origin)[1])
                                        })

                                    }

                                    //return;
                                }
                            } catch (e) {
                            }
                        }
                    }
                }}
                MenuContextValue={{
                    isActive: (props) => {

                        if (typeof props.active == 'boolean') {
                            return props.active
                        }

                        let href = props.href ? parentRoute + props.href : props.href || ''

                        if (!href) {
                            return false
                        }

                        const hrefWithoutSearch = href.split('?')[0]
                        const urlWithoutSearch = url.split('?')[0]

                        const isListPage = (url?.match('/page/') && !url?.endsWith('/page')) || url?.match('/limit') || url?.match('/sort') || url?.match(/\?search=/)

                        const starsWidthAndNext =
                            (url.startsWith(href + '/page') && !url.startsWith(href + '/pages')) ||
                            (url.startsWith(href + '/limit')) ||
                            (url.startsWith(href + '/sort')) ||
                            (url.startsWith(href + '\?search='))

                        return isListPage ? (starsWidthAndNext || hrefWithoutSearch.startsWith(urlWithoutSearch)) : (href === url)
                    },
                    getPropsForMenuItemProps: () => {
                        return {
                            user
                        }
                    }
                }}
                MenuProps={{
                    storageOpenMenu: storage,
                    menuKey: 'vlmMainMenu'
                }}
                UserMenuDesktopProps={{
                    storageOpenMenu: storage,
                    storageUserMenuScrollTop: storage,
                    menuKey: 'vlmUserMenu'
                }}
                MobileMenuProps={{
                    storageDrawerScrollTop: storage
                }}
                ThemeControlsProps={{
                    storageOpenMenu: storage
                }}
                FooterProps={{
                    footerSubtitle: 'Vendéglátos munkák az egész országban',
                    footerChildren: [
                        /*{
                            menu: [
                                { label: appContext.menus.jobsMenu, href: appContext.routes.jobsRoute },
                                { label: 'Munkáltatóknak', href: '/munkaltatoknak' },
                                { label: 'Munkavállalóknak', href: '/munkavallaloknak' },
                                { label: 'Árak', href: '/arak' }
                            ]
                        },*/
                        {
                            menu: [
                                { label: 'ÁSZF', href: '/aszf' },
                                { label: 'Adatkezelés', href: '/adatkezeles' },
                                { label: 'Működési szabályzat', href: '/mukodesi-szabalyzat' },
                                { label: 'Munkáltatóknak', href: '/munkaltatoknak' },
                                {
                                    label: 'Kredit vásárlás',
                                    href: (p)=>{
                                        return p?.user?._id ? appContext.routes.orderRoute + '/new' : appContext.routes.accountRoute + '/login' + '?redirect=' + encodeURIComponent(appContext.routes.orderRoute + '/new')
                                    },
                                },
                                { label: 'Árak', href: '/arak' },
                                { label: 'Kapcsolat', href: '/kapcsolat' },
                                { label: 'Munkavállalóknak', href: '/munkavallaloknak' },
                            ]
                        }
                        /*...user?._id ? [] : [{
                            menu: [
                                {
                                    label: appContext.menus.signupMenu,
                                    href: appContext.routes.accountRoute + '/signup'
                                },
                                { label: appContext.menus.loginMenu, href: appContext.routes.accountRoute + '/login' }
                            ]
                        }]*/
                    ],
                    footerSocialMenu: [
                        {
                            startIcon: <FacebookIcon />,
                            label: 'Facebook',
                            style: {
                                '--vlm-icon-font-size': '1.5em'
                            },
                            href: 'https://www.facebook.com/vendeglatosmunkak.hu',
                            target: '_blank'
                        },
                        {
                            startIcon: <InstagramIcon />,
                            label: 'Instagram',
                            style: {
                                '--vlm-icon-font-size': '1.5em'
                            },
                            href: 'https://www.instagram.com/vendeglatosmunkak.hu/',
                            target: '_blank'
                        }
                    ],
                    footerCopyright: 'Vendéglátós munkák ' + new Date().getFullYear() + '. ©',
                    footerPaymentsImage:
                        <Button
                            href={'https://barion.com'}
                            target={'_blank'}
                            variant={'text'}
                            size={'none'}
                            ButtonBaseContentComponent={() => {
                                return (
                                    <Image
                                        className={classNames.barionImage}
                                        src={barionImage}
                                        height={'20px'}
                                        width={'244px'}
                                    />
                                )
                            }}
                        />
                }}
                fullPage={fullPage}
                LogoProps={{
                    christmas: true
                }}
            >
                {
                    (requestPath.startsWith(routes.accountRoute)) ?
                        <Account />
                        :
                        (PostTypesComponent) ?
                            <PostTypesComponent {...PostTypeComponentProps} />
                            :
                            <>
                                {!probablyWaitingForLogout ? <NotFound /> : null}
                            </>
                }
                <Cookies />
                <Dialog
                    effect={({ actions }) => {
                        dialog.actions = actions
                    }}
                    ContentComponentProps={{
                        Component: 'div'
                    }}
                />
                <NewReleasePopup
                    effect={({ actions }) => {
                        newRelease.actions = actions
                    }}
                />
            </Template>
            <OfflineLayer
                effect={({ actions }) => {
                    offlineLayer.actions = actions
                }}
                classNames={classNames}
                show={networkStateOffline.current}
            />
        </AppContext.Provider>
    )
}
