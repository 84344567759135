import defaultPostTypeConfig from '../post'
import capitalize from '../../utils/capitalize'

export default function getConstants(p = {}) {

    const name = 'apply'

    const n = name
    const N = capitalize(n)
    const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'
    const Ns = capitalize(ns)

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    })

    return {
        labels: {
            ...labels,
            ['new' + N + 'SubmitLabel']: 'Jelentkezem az állásra',
            ['restore' + N + 'SubmitLabel']: 'Visszaállítás',
            ['save' + N + 'SubmitLabel']: 'Mentés',
            ['cancel' + N + 'Text']: 'Mégsem',
            ['delete' + N + 'Text']: 'Törlés',
            ['delete' + Ns + 'Text']: 'Jelentkezések törlése',
            ['ban' + N + 'Text']: 'Tiltás',
            ['approve' + N + 'Text']: 'Jóváhagyás',
            ['protection' + N + 'Text']: 'Védettnek jelölés',
            ['removeProtection' + N + 'Text']: 'Védettség megszüntetése',
            ['select' + N + 'Text']: 'Kiválasztás',
            ['add' + N + 'Text']: 'Hozzáadás',
            ['moreFromMy' + Ns + 'Title']: 'További jelentkezéseim',
            ['moreFromUser' + Ns + 'Title']: 'További jelentkezések',

            [ns + 'SortRelevance']: 'Relevancia',

            [n + 'MarkIrrelevant']: 'Törlés',
            [n + 'RemoveMarkIrrelevant']: 'Visszaállítás',
            [n + 'AppliesForMeTrash']: 'Törölt jelentkezések',
            [n + 'AppliesForMeBanned']: 'Lejárt jelentkezések',
            [n + 'AvatarSecondaryTextProfile']: 'Profil megtekintése',

            [n + 'AllLabel']: 'Összes',
            [n + 'OtherFilters']: 'További szűrők',

            [name + 'ShowInfoTitle']: '',
        },
        menus: {
            ...menus,
            ['user' + Ns + 'Menu']: 'Jelentkezések',
            ['my' + Ns + 'Menu']: 'Jelentkezéseim',
            ['new' + N + 'Menu']: 'Új jelentkezés',
            [ns + 'Menu']: 'Jelentkezések',

            ['my' + Ns + 'ForMeMenu']: 'Jelentkezések a hirdetéseimre',
            ['user' + Ns + 'ForMeMenu']: 'Jelentkezések a hirdetéseire',

            ['deleted' + Ns + 'Menu']: 'Törölt jelentkezések',
            ['banned' + Ns + 'Menu']: 'Lejárt jelentkezések',
            ['protection' + Ns + 'Menu']: 'Védett jelentkezések',
            ['missingData' + Ns + 'Menu']: 'Hiányzó adatok',
            ['lowAuthorStatus' + Ns + 'Menu']: 'Szerző alacsony státusszal',
            ['edit' + N + 'Menu']: 'Szerkesztés',
            ['delete' + N + 'Menu']: 'Törlés',
            ['ban' + N + 'Menu']: 'Tiltás',
            ['approve' + N + 'Menu']: 'Jóváhagyás',
            ['protection' + N + 'Menu']: 'Védettnek jelölés',
            ['removeProtection' + N + 'Menu']: 'Védettség törlése'


        },
        messages: {
            ...messages,
            ['new' + N + 'SuccessMessage']: 'Jelentkezésedet rögzítettük',
            ['save' + N + 'SuccessMessage']: 'A jelentkezés mentésre került',
            ['delete' + N + 'Success']: 'A jelentkezés törölve lett',
            ['delete' + Ns + 'Success']: 'A jelentkezések törölve lettek',
            ['delete' + N + 'Question']: 'Biztosan törli az jelentkezést?',
            ['delete' + Ns + 'Question']: 'Biztosan törli az jelentkezésekat?',
            ['ban' + N + 'Success']: 'A jelentkezés véglegesen törölve lett',
            ['ban' + N + 'Question']: 'Biztos, hogy az jelentkezést véglegesen törölni akarod?',
            ['approve' + N + 'Success']: 'A jelentkezés jóvá lett hagyva',
            ['approve' + N + 'Question']: 'Biztosan jóváhagyod az jelentkezést',
            ['protection' + N + 'Success']: 'A jelentkezés védettnek jelölve',
            ['protection' + N + 'Question']: 'Biztosan védettnek jelölöd az jelentkezést?',
            ['removeProtection' + N + 'Success']: 'A védettnek jelölés törölve lett',
            ['removeProtection' + N + 'Question']: 'Biztosan törlöd a védettnek jelölést?',
            ['thereAreNo' + Ns]: 'Nincsenek jelentkezések',

            [n + 'MarkIrrelevantSuccessMessage']: 'A jelentkezés törölve lett',
            [n + 'RemoveMarkIrrelevantSuccessMessage']: 'A jelentkezés vissza lett állítva',
            [n + 'NotFoundNotAvailable']: (p = {}) => {
                const { user } = p
                return user?._id ? 'Ez a jelentkezés már nem elérhető' : 'Lépj be, hogy megtekinthesd a jelentkezést'
            },
            [name + 'ShowInfoContent']: 'Ahhoz, hogy láthasd a hirdetés elérhetőségeit, előbb küldd el a jelentkezésed!'
        },
        routes: {
            ...routes
        },
        titles: {
            ...titles,
            [n + 'Title']: 'Jelentkezés',
            [ns + 'Title']: 'Jelentkezések',
            ['new' + N + 'Title']: 'Új jelentkezés',
            ['edit' + N + 'Title']: 'Jelentkezés szerkesztése',
            ['user' + Ns + 'Title']: 'Jelentkezések',
            ['userDeleted' + Ns + 'Title']: 'Törölt jelentkezések',
            ['userBanned' + Ns + 'Title']: 'Lejárt jelentkezések',
            ['my' + Ns + 'Title']: 'Jelentkezéseim',
            ['myDeleted' + Ns + 'Title']: 'Törölt jelentkezéseim',
            ['myBanned' + Ns + 'Title']: 'Lejárt jelentkezéseim',

            ['user' + Ns + 'ForMeTitle']: 'Jelentkezések a hirdetéseire',
            ['my' + Ns + 'ForMeTitle']: 'Jelentkezések a hirdetéseimre',

            ['user' + Ns + 'ForMeDeletedTitle']: 'Jelentkezések a hirdetéseire',
            ['my' + Ns + 'ForMeDeletedTitle']: 'Jelentkezések a hirdetéseimre',

            ['user' + Ns + 'ForMeBannedTitle']: 'Jelentkezések a hirdetéseire',

            ['dialogDelete' + N + 'Title']: 'Jelentkezés törlése',
            ['dialogBan' + N + 'Title']: 'Jelentkezés törlése véglegesen',
            ['dialogApprove' + N + 'Title']: 'Jelentkezés jóváhagyása',
            ['dialogProtection' + N + 'Title']: 'Jelentkezés védettnek jelölése',
            ['dialogRemoveProtection' + N + 'Title']: 'Jelentkezés védettségének megszüntetése',

            ['dialogDelete' + Ns + 'Title']: 'Jelentkezések törlése',

            ['statusBanned' + N + 'Title']: 'Lejárt',
            ['statusDeleted' + N + 'Title']: 'Törölt',
            ['statusAuthorDeleted' + N + 'Title']: 'Szerző törölve',
            ['statusMissingData' + N + 'Title']: 'Hiányzó adatok',
            ['statusApproved' + N + 'Title']: 'Jóváhagyott',
            ['statusFeatured' + N + 'Title']: 'Védett',
            ['statusCreated' + N + 'Title']: 'Létrehozva',

            ['banned' + Ns + 'Title']: 'Lejárt jelentkezések',
            ['deleted' + Ns + 'Title']: 'Törölt jelentkezések',
            ['missingData' + Ns + 'Title']: 'Hiányzó adatok',
            ['protected' + Ns + 'Title']: 'Védett jelentkezések',
            ['lowAuthorStatus' + Ns + 'Title']: 'Szerző alacsony státusszal',

            ['select' + Ns]: 'Jelentkezések kiválasztása',
            ['select' + N]: 'Jelentkezés kiválasztása'

        }
    }
}
